import { React, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import constants from '../Usables/Constants';
import ChartsComponent from './ChartsComponent';
import useDataFetcher from './useDataFetcher';
import {qstn_data} from './Graph/wmJsonData';


const PPBTotalResults = () => {
    let location = useLocation();
    const company = constants.reporting_countries[location.state.country].company;
    const report = location.state.report
    const country = location.state.country
    const sectionColor = undefined; 

    const section_obj = {
        name: 'PPB Total',
        url_part: 'ppb_total_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(report,country, section_obj);

    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }

    const report_type = (location.state.report === 1) ? 'PPB' : 'BCB';

    const charts = [
        ...(Object.keys(data.nps).length>0? [{   // Line Graph
            title: company.toUpperCase() + ' NPS SCORE',
            chart_type: 'line',
            sectionTitleColor: sectionColor,
            icon: 'speed',
            ...data.nps,
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B1 : qstn_data["BCB"]?.B1_1
        }]:[]),
        ...(Object.keys(data.rdist).length>0? [{   // Stacked bar graph
            title: company.toUpperCase() + ' NPS RATING DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor: sectionColor,
            icon: 'vertical_distribute',
            ...data.rdist,
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B1 : qstn_data["BCB"]?.B1_2
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores - PROMOTERS
            title: 'STANBIC BANK NPS REASONS | PROMOTERS',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Promoters,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B2b : qstn_data["BCB"]?.['2(b)']
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores - PASSIVE
            title: 'STANBIC BANK NPS REASONS | PASSIVES',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Passives,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B2a: qstn_data["BCB"]?.['2(a)']
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores -  DETRACTORS
            title: 'STANBIC BANK NPS REASONS | DETRACTORS',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Detractors,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B2a : qstn_data["BCB"]?.['2(a)']
        }]:[]),
        ...(Object.keys(data.bank_recommendation).length>0  ? [{   // Bar Graph with a line
            title: company.toUpperCase() + ' RECOMMENDATION',
            chart_type: 'column',
            sectionTitleColor: sectionColor,
            icon: 'trending_up',
            ...data.bank_recommendation,
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B12 : qstn_data["BCB"]?.B12
        }]:[]),
        ...(Object.keys(data.satisfaction).length>0? [{   // SideBar Graph 
            title: 'OVERALL SATISFACTION WITH ' + company.toUpperCase(),
            chart_type: 'satisfaction_bars',
            sectionTitleColor: sectionColor,
            data: data.satisfaction.data,
            sample_size_data: data.satisfaction.sample_size_data,
            shield_data: data.satisfaction.shield_data,
            chart_shield: data.satisfaction.chart_shield,
            colors: ['#FF0000', '#FF4500','#FFA500', '#FFD700' ,'#FFFF00', '#ADFF2F', '#7CFC00','#32CD32', '#00FF00' ,'#008000'],
            icon: 'theater_comedy',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B6 : qstn_data["BCB"]?.B6
        }]:[])
    ];

    // console.log(charts);

    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
            isMarket = {report === 2 || report === 3 ? false : true }
            isSegment = { report === 3  ? false : true }
        />
    );
};

export default PPBTotalResults;
