import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Cell, LabelList, ResponsiveContainer, Tooltip } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';

function Double_BarCharts_statisfation({ data, shield_data, chart_shield, sample_size_data, qstn_data = ""}) {

  const side_bar_graph_colors = ['#FF0000', '#FF4500', '#FFA500', '#FFD700', '#FFFF00', '#ADFF2F', '#7CFC00', '#00FF00','#32CD32','#008000'].reverse();

  // Sort data by rating name
  data.sort((a, b) => {
    const ratingA = parseInt(a.name.match(/\d+/)[0]);
    const ratingB = parseInt(b.name.match(/\d+/)[0]);
    return ratingA - ratingB;
  }).reverse();

  const waveExists = (data, waveToCheck) => {
    return data.some(item => item && item.hasOwnProperty(waveToCheck));
  };

  const waveExist1 = waveExists(data, "Wave 1");
  const waveExist2 = waveExists(data, "Wave 2");

  // Map colors and merge with sample sizes
  data = data.map((data, index) => {
    const { name, Wave1, Wave2 } = data;
    const color = side_bar_graph_colors[index % side_bar_graph_colors.length];

    // Find the corresponding sample size
    const sampleSize = sample_size_data.find(item => item.name === name);
    
    return {
      name,
      'Wave 1': data['Wave 1'],
      'Wave 2': data['Wave 2'],
      color,
      sampleSize1: sampleSize ? sampleSize['Wave 1'] :0,
      sampleSize2: sampleSize ? sampleSize['Wave 2'] :0
    };
  });
 
  const dmnRng = [0, 80];

  return (
    <>
      <div style={{ width: '100%', height: '90%' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '90% 10%', width: '100%', height: '95%' }}>
          <div style={{ width: '100%', height: '100%' }}>

          {waveExist2 && (
              <div style={{ display: 'flex', height: waveExist1 ? '45%' : '90%' }}>
                {/* The grapg for Wave 1*/}
                <div style={{ position: 'relative', display: 'flex' }}>
                  <SmallShield
                    scale="100%"
                    secondDivOffset='-10px'
          
                    waveTxt={chart_shield[0] && chart_shield[0].waveTxt || ""}
                    waveSample={chart_shield[0] && chart_shield[0].waveScore || ""}
                    waveScore={chart_shield[0] && chart_shield[0].waveSample || ""}
                    fill={chart_shield[0] && chart_shield[0].fill || ""}
                  />
                </div>
                <ResponsiveContainer width="100%" style={{ paddingLeft: '20px' }}>
                  <BarChart
                    data={data}
                    layout="vertical"
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <XAxis type="number" tick={{ fill: '#ffffff' }} domain={dmnRng} />
                    <YAxis type="category" tickSize={10} hide />
                    <Tooltip 
                      formatter={(value, name, props) => {
                        const sampleSize = props.payload.sampleSize2;
                        return [`${value} (n = ${sampleSize})`, name];
                      }} 
                      contentStyle={{ backgroundColor: '#ffffff', border: '1px solid #ccc' }} 
                    />
                    <Bar dataKey="Wave 2" fill="#8884d8">
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                      <LabelList dataKey="Wave 2" position="right" style={{ fill: '#ffffff' }}  formatter={(value) => `${value}%`}/>
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}

          {waveExist1 && (
            <div style={{ display: 'flex', height: waveExist2 ? '45%' : '90%' }}>
              {/* The graph for Wave 2*/}
              <div style={{ position: 'relative', display: 'flex' }}>
                <SmallShield
                  scale="100%"
                  secondDivOffset='-10px'
                  waveTxt= {waveExist2 ? chart_shield[1] && chart_shield[1].waveTxt || "" : chart_shield[0] && chart_shield[0].waveTxt || ""}
                  waveSample={waveExist2 ? chart_shield[1] && chart_shield[1].waveScore || "" : chart_shield[0] && chart_shield[0].waveScore || ""}
                  waveScore= {waveExist2 ? chart_shield[1] && chart_shield[1].waveSample || "" : chart_shield[0] && chart_shield[0].waveSample || ""}
                  fill= { waveExist2 ? chart_shield[1] && chart_shield[1].fill || "" :  chart_shield[0] && chart_shield[0].fill || ""}
                />
              </div>

              <ResponsiveContainer width="100%" style={{ paddingLeft: '20px' }}>
                <BarChart
                  data={data}
                  layout="vertical"
                  margin={{
                    top: 20,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  {!waveExist2 ? <XAxis type="number" tick={{ fill: '#ffffff' }} domain={dmnRng} /> : <XAxis type="number" tick={{ fill: '#ffffff' }} domain={dmnRng} hide />}
                  <YAxis type="category" tickSize={10} hide />
                  <Tooltip 
                    formatter={(value, name, props) => {
                      const sampleSize = props.payload.sampleSize1;
                      return [`${value}% (n = ${sampleSize})`, name];
                    }} 
                    contentStyle={{ backgroundColor: '#ffffff', border: '1px solid #ccc' }} 
                  />
                  <Bar dataKey="Wave 1" fill="#8884d8">
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                    <LabelList dataKey="Wave 1" position="right" style={{ fill: '#ffffff' }}  formatter={(value) => `${value}%`}/>
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
             
            </div>
          )}

          </div>
          <div style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end', 
            alignItems: 'flex-end', 
          }}>
            {/* Shield on the right */}
            <SmallShield
              scale='80%'
              waveTxt={shield_data.waveTxt}
              fill={shield_data.fill}
            />
          </div>
        </div>

       {/* The legend */}
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '30px' }}>
          {[...data].reverse().map((entry, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 10px',
                paddin:"0px"
              }}
            >
              <p style={{ fontSize: '12px', margin: '0 5px' }}>{entry.name}</p>
              <div style={{ width: '15px', height: '15px', backgroundColor: entry.color }} />
            </div>
          ))}
        </div>
        <p style={{ textAlign: "center" , marginTop: "0px", padding: "0"}}>{qstn_data}</p>
      </div>
    </>
  );
}

export default Double_BarCharts_statisfation;
