import React, { useEffect, useState } from 'react';
import getImageAspectRatio from './getImageAspectRatio';

const ShiledButton = ({ img_icon, svgHeight, svgWidth}) => {
    const imageHeight = svgHeight*0.3; // the height of your image
    // const imageWidth = imageHeight; // 

    const M = `${svgWidth * 0.5},${svgHeight * 0.99}`; // 
    const Q1 = `${svgWidth * 0.1},${svgHeight * 0.83}`; // 
    const L1 = `${svgWidth * 0.15},${svgHeight * 0.50}`; // 
    const L2 = `${svgWidth * 0.2},${svgHeight * 0.33}`; // 
    const Q2 = `${svgWidth * 0.5},${svgHeight * 0.23}`; // 
    const L3 = `${svgWidth * 0.8},${svgHeight * 0.33}`; // 
    const L4 = `${svgWidth * 0.85},${svgHeight * 0.50}`; // 
    const Q3 = `${svgWidth * 0.9},${svgHeight * 0.83}`; //
    const Z = `${svgWidth * 0.5},${svgHeight * 0.99}`; // 

    const [aspectRatio, setAspectRatio] = useState(null);

    useEffect(() => {
        getImageAspectRatio(img_icon)
        .then(aspectRatio => setAspectRatio(aspectRatio))
        .catch(error => console.error(error));
    }, [img_icon]);

    return (
        <svg style={{border:"1px solid transparent"}} width={svgWidth} height={svgHeight} viewBox={`0 30 ${svgWidth} ${svgHeight}`}>
            <path d={`M${M} Q${Q1} ${L1} L${L2} Q${Q2} ${L3} L${L4} Q${Q3} ${Z}z`}
                    fill="#034a76a0" stroke="#D3D3D3" strokeWidth="8"/>
            <image href={img_icon} y={(svgHeight/2) - imageHeight / 5} x={(svgWidth - imageHeight * aspectRatio) / 2} height={imageHeight} />
        </svg>
        
    );
};

export default ShiledButton;