import { useState, useEffect } from 'react';
// import constants from '../Usables/Constants';
import { fetchData } from '../../services/authService';

const get_filters = async (report, country, section_name, selectedMarket, set_filters_data, setLoading) => {
    const filters_url = `get_filter_values/${report}/${country}/${section_name}/${selectedMarket}/`;
    setLoading(true);
    try {
        const response = await fetchData(filters_url);
        set_filters_data(response);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching data: ', error);
        setLoading(false);
    }
};

export const get_latest_year = async (report, country, setYear) => {
    const url = `get_latest_year_with_data/${report}/${country}/`;
    try {
        const response = await fetchData(url);
        setYear(response.latest_year);
        return setYear(response.latest_year);
    } catch (error) {
        // console.error('Error fetching data: ', error);
    }
    return setYear(new Date().getFullYear());
};

export const get_oldest_year = async (report, country, setYear) => {
    const url = `get_oldest_year_with_data/${report}/${country}/`;
    try {
        const response = await fetchData(url);
        setYear(response.oldest_year);
        return setYear(response.oldest_year);
    } catch (error) {
        // console.error('Error fetching data: ', error);
    }
    return setYear(2021);
};

const current_year = new Date().getFullYear();

const useDataFetcher = (report, country, section_obj, initialMarket = 'None', initialSegment = 'None') => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [filters_data, set_filters_data] = useState({ "years": [], "markets": ["None"], "segments": ["None"] });

    const [selectedYear, setSelectedYear] = useState(current_year);

    useEffect(() => {
        get_latest_year(report, country, setSelectedYear);
    }, [report, country]);

    const [selectedMarket, setSelectedMarket] = useState(initialMarket);
    const [selectedSegment, setSelectedSegment] = useState(initialSegment);

    const handleFilterChange = (selectedValue, setState, market) => {
        setState(selectedValue);
        if (market.is) {
            setSelectedSegment(0);
        }
        get_filters(report, country, section_obj.name, selectedMarket, set_filters_data, setLoading);
    };

    useEffect(() => {
        get_filters(report, country, section_obj.name, selectedMarket, set_filters_data, setLoading);
    }, [section_obj.name, selectedMarket, report, country]);

    useEffect(() => {
        if (selectedYear) {
            setLoading(true);
            const fetchUrl = `${section_obj.url_part}/${report}/${country}/${selectedYear}/${selectedMarket}/${selectedSegment}/`;
            fetchData(fetchUrl)
                .then(response => {
                    setData(response);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setLoading(false);
                });
        }
    }, [section_obj.url_part, report, country, selectedYear, selectedMarket, selectedSegment]);

    return { data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, selectedSegment, setSelectedSegment, handleFilterChange };
};

export default useDataFetcher;