import React, { useState, useEffect } from 'react';
import std_emblem from '../imgs/std_emblem.png'; 
import '../styles/TopBar.css';
import constants from './Usables/Constants';
import { get_latest_year } from './Results/useDataFetcher';
import { get_oldest_year } from './Results/useDataFetcher';

const TopBar = ({country, report}) => {
    const year = new Date().getFullYear();
    const [category] = useState(''); // Replace with the category
    const [path] = useState('../'); // Replace with the path to your images
    const [latestYear, setLatestYear] = useState(year)
    const [oldestYear, setOldestYear] = useState(2021)

    useEffect(() => {
        get_latest_year(report, country, setLatestYear);
    }, [report, country]);
    useEffect(() => {
        get_oldest_year(report, country, setOldestYear);
    }, [report, country]);

    const year_from_and_year_to = (oldestYear !== latestYear)? `${oldestYear} - ${latestYear}` : latestYear
    
    const company = constants.reporting_countries.hasOwnProperty(country)? constants.reporting_countries[country].company:'Standard Bank';
    return (
        <nav className="TopBar" >
            <div className="headers">
                <div className="h-item">
                    <div className='flexing center_v_and_h' style={{ paddingLeft: '5vw' }}>
                        <div>
                            <img className="LogoPart responsive-img" src={`${std_emblem}`} alt=""/>
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <div style={{ fontSize: 'xx-large', }}><b>{company}</b></div>
                            <div style={{ color: 'transparent' }}>.</div>
                        </div>
                    </div>
                </div>
                <div className="h-item">
                    <div className="NavText"> 
                        <span><b>| AFRICA REGIONS NPS {year_from_and_year_to}</b></span>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default TopBar;