import React, { useState } from 'react';
import { XAxis, YAxis, ResponsiveContainer, Legend, Tooltip, LineChart, Line } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';
import WeightedNote from '../../Usables/WeightedNote';

function LineCharts({ data, lineColors, shield_data, sample_size, qstn_data = "" }) {
    // State to keep track of which data series is hovered.
    const [hoveredKey, setHoveredKey] = useState(null);
    const is_weighted = Object.keys(data[0]).some(name => name.includes('*'));

    // Custom Tooltip now uses hoveredKey to filter the payload.
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && hoveredKey) {
            // Filter the payload to show only the hovered data series.
            const filteredPayload = payload.filter(entry => entry.dataKey === hoveredKey);
            const currentSample = sample_size.find(sample => sample.name === label);

            return (
                <div style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '10px',
                    borderRadius: '5px',
                    color: '#000',
                    fontWeight: 'bold'
                }}>
                    <p style={{ marginBottom: '5px', fontSize: '14px' }}>{label}</p>
                    {filteredPayload.map((entry, index) => {
                        const sampleText = currentSample ? currentSample[entry.dataKey] : 'N/A';
                        return (
                            <p
                                key={`item-${index}`}
                                style={{ color: entry.stroke, margin: '5px 0', fontSize: '12px' }}
                            >
                                {`${entry.name}: ${entry.value} (n = ${sampleText})`}
                            </p>
                        );
                    })}
                </div>
            );
        }
        return null;
    };

    // Updated CustomDot component with a 30x30 box always centered.
    function CustomDot(props) {
        const { cx, cy, stroke, value, dataKey } = props;
    
        return (
            <svg
                x={cx - 15}  // center the svg horizontally
                y={cy - 15}  // center the svg vertically
                width={30}
                height={30}
                viewBox="0 0 30 30"
                onMouseEnter={() => setHoveredKey(dataKey)}
                onMouseLeave={() => setHoveredKey(null)}
            >
                <rect
                    x="0"
                    y="0"
                    width="30"
                    height="30"
                    fill={stroke} // Always filled with the stroke color
                    stroke={stroke}
                    strokeWidth="2"
                />
                <text
                    x="15"
                    y="15"
                    fill="white"
                    fontSize="12"
                    textAnchor="middle"
                    dominantBaseline="middle"
                >
                    {value}
                </text>
            </svg>
        );
    }
    
    const side_space = 250;
    const colors = lineColors;
    const keysFromObject = Object.keys(data[0]);
    keysFromObject.shift(); // Remove the first key (name)

    return (
        <div style={{ position: 'relative', width: '100%', height: '90%' }}>
            <ResponsiveContainer minWidth={600}>
                <LineChart data={data}>
                    <XAxis
                        dataKey="name"
                        tickLine={{ stroke: 'white' }}
                        axisLine={{ stroke: '#ffffff' }}
                        padding={{ right: side_space, left: side_space }}
                        tick={{ fill: 'white' }}
                        fontSize={16}
                    />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        margin={10}
                        tick={{ fill: '#ffffff' }}
                        fontSize={14}
                    />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Legend
                        verticalAlign="top"
                        layout="vertical"
                        align="right"
                        iconType="Square"
                        formatter={(value) => (
                            <span style={{ color: 'white' }}>
                                {value}
                            </span>
                        )}
                    />
                    {keysFromObject.map((item, index) => (
                        <Line
                            key={index}
                            dataKey={item}
                            stroke={colors[index]}
                            strokeWidth={5}
                            fill={colors[index]}
                            // Pass the dataKey to the CustomDot through a render function.
                            dot={(dotProps) => <CustomDot {...dotProps} dataKey={item} />}
                            activeDot={false}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>

            <div style={{
                position: 'absolute',
                bottom: '0',
                right: '0',
                margin: '10px'
            }}>
                <SmallShield
                    scale='80%'
                    fill={shield_data.fill}
                    waveTxt={shield_data.waveTxt}
                />
            </div>
            {is_weighted && <WeightedNote />}

            <p style={{ textAlign: "center" , marginTop: "6px"}}>{qstn_data}</p>
        </div>
    );
}

export default LineCharts;