import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import SmallShield from '../../Usables/SmallSheild';
import WeightedNote from '../../Usables/WeightedNote';

function PieChartComponent({ data, colors, shield_data, qstn_data = "" }) {
    const data1 = [
        { name: 'Considering moving bank', value: 6 },
        { name: 'Not defecting but rated < 6', value: 4 },
        { name: 'Happy with bank', value: 90 }
    ];

    const COLORS = ['#ff8042', '#00C49F', '#0088FE'];

    const renderCustomizedLabel = ({ percent, x, y, midAngle, innerRadius, outerRadius, value, index }) => {
        const RADIAN = Math.PI / 180;
        // Bring labels much closer by reducing the gap from the outer edge
        const radius = innerRadius + (outerRadius - innerRadius) * 0.3; // Adjusted to position labels closer to slices
        const xPos = x + radius * Math.cos(-midAngle * RADIAN) + 60;
        const yPos = y + radius * Math.sin(-midAngle * RADIAN) ;

        return (
            <text 
                x={xPos} 
                y={yPos} 
                fill={COLORS[index]} 
                textAnchor="middle" 
                dominantBaseline="middle" 
                fontSize={16}
                fontWeight="bold"
            >
                {`${data[index].name}: ${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '90%' }}>
            <ResponsiveContainer minWidth={700} >
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius="60%"                       
                        fill="#8884d8"
                        labelLine={false} 
                        label={renderCustomizedLabel}
                        isAnimationActive={true}
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                                stroke="white" 
                                strokeWidth={3} 

                            />
                        ))}
                    </Pie>
                    <Tooltip />
                   
                </PieChart>
                <p style={{ textAlign: "center" , paddingBottom: "20px"}}>{qstn_data || ""}</p>
            </ResponsiveContainer>

            <div style={{
                position: 'absolute',
                bottom: '0',
                right: '0',
                margin: '10px'
            }}>
                <SmallShield
                    scale="80%"
                    fill={shield_data.fill}
                    waveTxt={shield_data.waveTxt}
                />
            </div>

            <WeightedNote />
        </div>
    );
}

export default PieChartComponent;