// /frontend/src/components/Auth/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/login.css';
import { login } from '../../services/authService';

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('access_token'); // Clear old tokens on page load
        localStorage.removeItem('refresh_token');
        props.setIsLoggedIn(false);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const user = await login(email, password);
            if (user) {
                localStorage.setItem('isLoggedIn', true);
                props.setIsLoggedIn(true);

                if (!user.is_staff && user.country !== 'South Africa') {
                    navigate('/home', { state: { country: user.country, user } });
                } else {
                    navigate('/choose-country', { state: { user } });
                }
            } else {
                alert('Invalid credentials. Please try again.');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('Login failed. Please check your credentials.');
        }
    };

    return (
        <div>
            <div className="section"></div>
            <div className="login-box">
                <form onSubmit={handleSubmit} className="col s12">
                    <div id="login_emblem">
                        <div className="le_line" id="le_line_left"></div>
                        <div id="le_circle">
                            <i className="material-icons user-icon le_icon">person_outline</i>
                        </div>
                        <div className="le_line" id="le_line_right"></div>
                    </div>
                    <div className="textbox-cont">
                        <div className="icon-cont">
                            <i className="material-icons user-icon">person_outline</i>
                        </div>
                        <input 
                            type="email" 
                            name="uid" 
                            id="uid" 
                            className="validate" 
                            required 
                            placeholder="Username" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                        />
                    </div>
                    <div className="textbox-cont">
                        <div className="icon-cont">
                            <i className="material-icons user-icon">lock_outline</i>
                        </div>
                        <input 
                            type="password" 
                            name="pwd" 
                            id="pwd" 
                            className="validate" 
                            required 
                            placeholder="Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                        />
                    </div>
                    <input type="submit" name="login" value="Sign In" />
                </form>
            </div>
        </div>
    );
};

export default Login;
