import { React, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import constants from '../Usables/Constants';
import ChartsComponent from './ChartsComponent';
import useDataFetcher from './useDataFetcher';
import {qstn_data} from './Graph/wmJsonData';

const DefectingResults = () => {
    let location = useLocation();
    const company = constants.reporting_countries[location.state.country].company;
    const report = location.state.report
    const country = location.state.country
    const sectionColor = undefined; 

    const section_obj = {
        name: 'Client at Risk',
        url_part: 'clients_at_risk_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(report,country, section_obj);

    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }
    
    const report_type = (location.state.report === 1) ? 'PPB' : 'BCB';

    const charts = [
        ...(Object.keys(data.pie).length>0? [{ 
            title: 'CLIENTS AT RISK OF DEFECTING' ,
            chart_type: 'pie_chart',
            sectionTitleColor: sectionColor,
            icon: 'speed',
            ...data.pie,
            qstn_data : qstn_data[report_type]?.Q15a
        }]:[]),
      
    ];


    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
            isMarket = {report === 2 || report === 3 ? false : true }
            isSegment = { report === 3  ? false : true }
        />
    );
};

export default DefectingResults;
