import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartsComponent from './ChartsComponent';
import useDataFetcher from './useDataFetcher';
import {qstn_data} from './Graph/wmJsonData';

const RelationshipManager = () => {
    let location = useLocation();
    const sectionColor = "linear-gradient(45deg, rgba(127,168,255, 0.8) 0%, rgba(127,168,255, 0.8) 100%)"; 

    const section_obj = {
        name: 'Relationship Manager',
        url_part: 'relationship_manager_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(location.state.report,location.state.country, section_obj, 'Private Bank');

    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }

    const report_type = (location.state.report === 1) ? 'PPB' : 'BCB';
    
    const charts = [
        ...(Object.keys(data.nps).length>0? [{    // Line Graph
            title: 'RELATIONSHIP MANAGER AVERAGE NPS',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            ...data.nps,
            icon: 'speed',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B14 : qstn_data["BCB"]?.B14
        }]:[]),
        ...(Object.keys(data.rdist).length>0? [{   // Stacked bargraph
            title: 'RELATIONSHIP MANAGER NPS DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor : sectionColor,
            ...data.rdist,
            icon: 'vertical_distribute',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B14 : qstn_data["BCB"]?.B14
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores - PROMOTERS
            title: 'RELATIONSHIP MANAGER NPS REASONS | PROMOTERS',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Promoters,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B15b : qstn_data["BCB"]?.["9a"]
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores - PASSIVE
            title: 'RELATIONSHIP MANAGER NPS REASONS | PASSIVES',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Passives,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B15a : qstn_data["BCB"]?.["9b"]
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores - DETRACTORS
            title: 'RELATIONSHIP MANAGER NPS REASONS | DETRACTORS',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Detractors,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B15a : qstn_data["BCB"]?.["9b"]
        }]:[]),
        ...(Object.keys(data.delivery_exp).length>0? [{   // Bar Graph with a line
            title: 'RELATIONSHIP MANAGER DELIVERY OF EXPERIENCE',
            chart_type: 'column',
            sectionTitleColor : sectionColor,
            ...data.delivery_exp,
            icon: 'trending_up',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B15c : qstn_data["BCB"]?.["9a"]
        }]:[]),
        ...(Object.keys(data.rm_awareness_and_contact).length>0? [{   
            // Frequency Sidebars
            title: 'RELATIONSHIP MANAGER CONFIRMATION AND INTERACTION FREQUENCY',
            chart_type: 'relationship_sidebars',
            sectionTitleColor : sectionColor,
            ...data.rm_awareness_and_contact,
            icon: 'theater_comedy',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B15d : qstn_data["BCB"]?.A1
        }]:[])
    ]

    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
            isMarket={false}
        />
    );
};

export default RelationshipManager;