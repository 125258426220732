import { React } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RingButton from '../Usables/RingButton.js';
import ppb_icon from '../../imgs/icons/ppb_icon.svg';
import bcb_icon from '../../imgs/icons/bcb_icon.svg';
import Flag from '../Usables/Flag.js';
import constants from '../Usables/Constants.js';
import CapitalFirstLetter from '../Usables/CapitalFirstLetter.js';

const Home = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const country = location.state.country;
    const isOffShore = country === "Offshore" 

    const handleSelect = (section) => {
        const overall_path = (location.state.country==='Overall')? '/Overall' : '';
        const final_section = isOffShore && section==="ppb"? "ipb" : section;
        navigate(`/${final_section}${overall_path}`, { state:{ country:location.state.country, user:location.state.user, report:constants.reports[final_section] } });
    };
    
    const img_size = 320;
    return (
        <div style={{height: "100%"}}>
            {/* <h2>Home</h2> */}
            <div className='circles_cont flexing center_v_and_h'>
                <div className='btn flexing center_v_and_h' onClick={() => handleSelect('ppb')}>
                    <RingButton imageUrl={ppb_icon} imgSize={img_size} />
                    <div className='section_name_cont'>
                        { isOffShore ? "IPB (INTERNATIONAL PERSONAL BANKING)"
                        :"PERSONAL & PRIVATE BANKING"
                        }</div>
                </div>
                <div className='btn flexing center_v_and_h' onClick={() => handleSelect('bcb')}>
                    <RingButton imageUrl={bcb_icon} imgSize={img_size} />
                    <div className='section_name_cont'>BUSINESS & COMMERCIAL BANKING</div>
                </div>

                <div style={{ position: 'absolute', right: 50, top: 150 }}>
                    <Flag 
                        img={location.state.country === 'Overall' 
                        ? constants.other_flags['Country Comparison'].img 
                        : location.state.country === 'Offshore'? constants.other_flags['Offshore'].img
                        : constants.country_flags[CapitalFirstLetter(location.state.country)].img} 
                        country={location.state.country.toUpperCase()} 
                    />
                </div>
            </div>
        </div>
        
    );
};

export default Home;
