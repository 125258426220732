
const getImageAspectRatio = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        
        img.onload = () => {
            resolve(img.width / img.height);
        };

        img.onerror = (error) => {
            reject(new Error(`Failed to load image: ${error.message}`));
        };
    });
};


export default getImageAspectRatio;