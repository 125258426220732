import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartsComponent from './ChartsComponent';
import constants from '../Usables/Constants';
import useDataFetcher from './useDataFetcher';
import {qstn_data} from './Graph/wmJsonData';

const Branch = () => {
    let location = useLocation();
    const company = constants.reporting_countries[location.state.country].company;
    const sectionColor = "linear-gradient(45deg, rgba(1,0,255, 0.8) 0%, rgba(1, 0, 255, 0.8) 100%)"; 

    const section_obj = {
        name: 'Branch',
        url_part: 'branch_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(location.state.report,location.state.country, section_obj, 'Personal Banking');


    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }

    
    const report_type = (location.state.report === 1) ? 'PPB' : 'BCB';

    const charts = [
        ...(Object.keys(data.nps).length>0? [{
            // Line Graph
            title: company.toUpperCase() + ' BRANCH NPS',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            ...data.nps,
            icon: 'trending_up',
            qstn_data : report_type==="PPB" ? qstn_data[report_type]['16c'] : qstn_data[report_type]['16c']
        }]:[]),
        ...(Object.keys(data.rdist).length>0? [{   
            // Stacked bargraph
            title: company.toUpperCase() + ' BRANCH NPS RATING DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor : sectionColor,
            ...data.rdist,
            icon: 'vertical_distribute',
            qstn_data : report_type==="PPB" ? qstn_data[report_type]['16c'] : qstn_data[report_type]['16c']
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores - DETRACTORS
            title: 'STANBIC BANK NPS REASONS | PROMOTERS​',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Promoters,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data[report_type]?.B16e : qstn_data[report_type]?.B16e
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores - PASSIVE
            title: 'STANBIC BANK NPS REASONS | PASSIVES​',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Passives,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data[report_type]?.B16d : qstn_data[report_type]?.B16d
        }]:[]),
        ...(Object.keys(data.nps_reasons).length>0? [{  
            // nps scores PROMOTERS
            title: 'STANBIC BANK NPS REASONS | DETRACTORS',
            chart_type: 'nps_reason_chart',
            sectionTitleColor : sectionColor,
            ...data.nps_reasons.Detractors,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data[report_type]?.B16d : qstn_data[report_type]?.B16d
        }]:[]),
        ...(Object.keys(data.delivery_exp).length>0? [{  
            // Bar Graph with a line
            title: 'CHANNEL DELIVERY OF EXPERIENCE',
            chart_type: 'column',
            sectionTitleColor : sectionColor,
            ...data.delivery_exp,
            icon: 'verified',
            qstn_data : report_type==="PPB" ? qstn_data[report_type]?.B16f : qstn_data[report_type]?.B16f
        }]:[]),
      
    ]
   
    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
            isMarket={false}
                       
        />
    );
};

export default Branch;