import React from 'react';
import { useLocation } from 'react-router-dom';
import ChartsComponent from './ChartsComponent';
import constants from '../Usables/Constants';
import useDataFetcher from './useDataFetcher';
import {qstn_data} from './Graph/wmJsonData';

const Others = () => {
    let location = useLocation();
    const sectionColor = "linear-gradient(45deg, rgba(0,153,153, 80%), rgba(0,153,153, 0.8) 100%)"; 
    const report = location.state.report
    const country = location.state.country
    
    const section_obj = {
        name: 'Other Banks',
        url_part: 'other_banks_results'
    }
    
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(report,country, section_obj);

    
    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    };


    const report_type = (location.state.report === 1) ? 'PPB' : 'BCB';

    const charts = [
        ...(Object.keys(data.nps).length>0? [{   // Line Graph
            title: 'OTHER BANKS NPS SCORE',
            chart_type: 'line',
            sectionTitleColor : sectionColor,
            ...data.nps,
            icon: 'speed',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B12 : qstn_data["BCB"]?.B12
        }]:[]),
        ...(Object.keys(data.rdist).length>0? [{   // Stacked bargraph
            title: 'OTHER BANKS NPS DISTRIBUTION',
            chart_type: 'multi_series_stacked_bar',
            sectionTitleColor : sectionColor,
            ...data.rdist,
            icon: 'vertical_distribute',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B4 : qstn_data["BCB"]?.B4
        }]:[]),
        ...(Object.keys(data.bank_recommendation).length>0? [{   // Bar Graph with line
            title: 'OTHER BANKS RECOMMENDATION',
            chart_type: 'column',
            sectionTitleColor : sectionColor,
            ...data.bank_recommendation,
            icon: 'assured_workload',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B4 : qstn_data["BCB"]?.B4
        }]:[]),
        ...(Object.keys(data.delivery_experience).length>0? [{
            title: 'OTHER BANKS DELIVERY OF EXPERIENCE SCORES',
            chart_type: 'delivery_exprience_bar',
            sectionTitleColor : sectionColor,
            ...data.delivery_experience,
            icon: 'trending_up',
            qstn_data : report_type==="PPB" ? qstn_data["PPB"]?.B13 : qstn_data["BCB"].B13
        }]:[])
    ]
    return (
        <ChartsComponent 
            location={location} 
            n_child_divs={charts.length}
            charts={charts}
            filters_data={filters_data}
            selected_year={selectedYear}
            set_selected_year={setSelectedYear}
            selected_market={selectedMarket}
            set_selected_market={setSelectedMarket}
            selected_segment={selectedSegment}
            set_selected_segment={setSelectedSegment}
            handleFilterChange={handleFilterChange}
            isMarket = {report === 2 || report === 3 ? false : true }
            isSegment = { report === 3  ? false : true }
        />
    );
};

export default Others;
