import React,{  useEffect} from 'react';
import useDataFetcher from '../Results/useDataFetcher';
import '../../styles/excel_data.css';
import { FilterDropdown } from '../Results/ChartsComponent';
import '../../styles/filters.css';

const InteractiveOverallTable = ({report, url}) => {
    const section_obj = {
        name: 'Overall NPS',
        url_part: 'overall_nps_results'
    }
    const isMarket = report === 2 || report === 3 ? false : true ;
    const isSegment =report === 3  ? false : true ;
    const { 
        data, loading, filters_data, selectedYear, setSelectedYear, selectedMarket, setSelectedMarket, 
        selectedSegment, setSelectedSegment, handleFilterChange 
    } = useDataFetcher(report,'0', section_obj);
    // useEffect(()=>{
    //     console.log(filters_data);
    // }, [filters_data])
    if (loading || !data) {
        return <div style={{ height:'100%',display:'flex',alignItems:'center',justifyContent:'center' }}>Loading...</div>;
    }
    // console.log(filters_data);
    const all_markets = selectedMarket==='None' || selectedMarket.includes('Total');
    const segment_none = selectedSegment==='None'
    const weighted_text = (segment_none && all_markets)? 'Weighted' : 'UnWeighted';

    return (
        <div className="table-container">
            {filters_data && 
                <div className='filters_div' style={{position:'absolute', right:-100, top: 35}}>
                    {isMarket && filters_data.hasOwnProperty('markets') &&
                        <FilterDropdown handleFilterChange={handleFilterChange} setSelected={setSelectedMarket} selected={selectedMarket} label="MARKET:" options={filters_data.markets} />
                    }
                    {isSegment && filters_data.hasOwnProperty('segments') &&
                        <FilterDropdown handleFilterChange={handleFilterChange} setSelected={setSelectedSegment} selected={selectedSegment} label="SEGMENT:" options={filters_data.segments} />
                    }
                </div>
            }
            <table className='table'>
                <thead>
                    <tr className='table-row'>
                        <th className='table-cell' rowSpan={2}>NPS Score {weighted_text}</th>
                        {data.final.markets_and_segments.map((segment, segmentIndex) => (
                            <th className='table-cell' key={`segment-${segmentIndex}`} colSpan={data.final.waves.length}>
                                {segment}
                            </th>
                        ))}
                    </tr>
                    <tr className='table-row'>
                        {data.final.markets_and_segments.flatMap((_, segmentIndex) =>
                            data.final.waves.map((wave, waveIndex) => (
                                <th className='table-cell' key={`wave-${segmentIndex}-${waveIndex}`}>{wave}</th>
                            ))
                        )}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data.final.table).map((country, countryIndex) => (
                        <tr className='table-row '>
                            <th>{country}</th>
                            {Object.keys(data.final.table[country]).map((segment, segIndex) => (
                                <React.Fragment key={`${country}-${segment}`}>
                                    {Object.keys(data.final.table[country][segment]).map((wave, waveIndex) => (
                                        <td className='table-cell'>{data.final.table[country][segment][wave]}</td>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
};

export default InteractiveOverallTable;
